<template>
  <div class="container min-height-sticky1 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-12">
        <div class="align-middle text-center">
          <svg
            height="128pt"
            viewBox="0 -10 468.0099 468"
            width="128pt"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m460.324219 293.9375v75h-452.640625v-75zm0 0" fill="#00acea" />
            <path
              d="m460.324219 7.746094v286.191406h-452.640625v-286.191406zm-134.96875 165.082031c0-3.601563-1.601563-7.203125-4.800781-7.203125h-4.300782v-7c0-3.296875-3.898437-4.699219-7.800781-4.699219-3.898437 0-7.796875 1.402344-7.796875 4.699219v7h-8.601562l17.699218-35.5c.292969-.589844.464844-1.238281.5-1.898438 0-3.601562-5.097656-5.898437-7.800781-5.898437-2.761719-.0625-5.308594 1.5-6.5 4l-23.097656 45.296875c-.507813.921875-.785157 1.953125-.800781 3 0 3.5 2.300781 5.402344 5.199218 5.402344h23.402344v10.597656c0 3.210938 3.898438 4.800781 7.796875 4.800781 3.902344 0 7.800781-1.589843 7.800781-4.800781v-10.597656h4.300782c2.398437 0 4.800781-3.601563 4.800781-7.199219zm-66.699219-.402344v-26.5c0-17.199219-10.800781-23.597656-24.601562-23.597656-13.800782 0-24.5 6.398437-24.5 23.597656v26.5c0 17.199219 10.699218 23.609375 24.5 23.609375 13.800781 0 24.601562-6.410156 24.601562-23.609375zm-62.703125.402344c0-3.601563-1.597656-7.203125-4.796875-7.203125h-4.300781v-7c0-3.296875-3.902344-4.699219-7.800781-4.699219-3.898438 0-7.800782 1.402344-7.800782 4.699219v7h-8.597656l17.699219-35.5c.292969-.589844.464843-1.238281.5-1.898438 0-3.601562-5.101563-5.898437-7.800781-5.898437-2.765626-.0625-5.308594 1.5-6.5 4l-23.101563 45.296875c-.507813.921875-.78125 1.953125-.796875 3 0 3.5 2.296875 5.402344 5.199219 5.402344h23.398437v10.597656c0 3.210938 3.902344 4.800781 7.800782 4.800781 3.898437 0 7.800781-1.589843 7.800781-4.800781v-10.597656h4.300781c2.398438 0 4.796875-3.601563 4.796875-7.199219zm0 0"
              fill="#00efd1"
            />
            <path d="m294.003906 368.9375 20 71.328125h-160l20-71.328125zm0 0" fill="#f4b844" />
            <path
              d="m243.054688 145.925781v26.5c0 6.902344-3.398438 10-9 10-5.601563 0-8.898438-3.097656-8.898438-10v-26.5c0-6.898437 3.296875-10 8.898438-10 5.601562 0 9 3.101563 9 10zm0 0"
              fill="#00efd1"
            />
            <g fill="#083863">
              <path
                d="m460.324219.0078125h-452.640625c-2.058594-.046875-4.050782.7539065-5.5 2.2187495-1.453125 1.464844-2.2421878 3.460938-2.17968775 5.519532v361.191406c-.07421875 2.09375.69921875 4.132812 2.14453175 5.648438 1.445312 1.519531 3.441406 2.390624 5.535156 2.421874h155.769531l-15.511719 55h-27.464844c-4.417968 0-8 3.582032-8 8 0 4.417969 3.582032 8 8 8h227.058594c4.417969 0 8-3.582031 8-8 0-4.417968-3.582031-8-8-8h-27.464844l-15.511718-55h155.765625c2.097656-.03125 4.09375-.902343 5.539062-2.421874 1.445313-1.515626 2.214844-3.554688 2.140625-5.648438v-361.191406c.0625-2.058594-.722656-4.054688-2.175781-5.519532-1.453125-1.464843-3.441406-2.2656245-5.503906-2.2187495zm-295.765625 431.9999995 15.511718-55h107.871094l15.511719 55zm287.445312-71h-436v-59h436zm0-75h-436v-270h436zm0 0"
              />
              <path
                d="m147.855469 181.007812h23.148437v9.617188c0 3.644531 4 5.300781 8 5.300781s8-1.65625 8-5.300781v-9.617188h4.152344c2.671875 0 5.296875-4.113281 5.296875-8 0-3.710937-1.65625-8-5.296875-8h-4.152344v-6.382812c0-3.835938-4.171875-5.199219-8-5.199219s-8 1.363281-8 5.199219v6.382812h-7.539062l17.351562-34.742187c.316406-.648437.5-1.351563.539063-2.070313 0-3.96875-5.375-6.382812-8.300781-6.382812-2.953126-.058594-5.667969 1.613281-6.945313 4.28125l-23.09375 45.289062c-.558594 1.078126-.851563 2.269532-.859375 3.480469 0 3.53125 2.289062 6.144531 5.699219 6.144531zm0 0"
              />
              <path
                d="m233.953125 196.535156c16.1875 0 25.050781-8.5625 25.050781-24.109375v-26.5c0-15.539062-8.863281-24.097656-25.050781-24.097656-16.121094 0-24.949219 8.558594-24.949219 24.097656v26.5c0 15.546875 8.828125 24.109375 24.949219 24.109375zm-7.949219-24.109375v-26.5c0-6.300781 2.878906-9.5 8.449219-9.5 5.640625 0 8.550781 3.199219 8.550781 9.5v26.5c0 6.300781-2.910156 9.5-8.550781 9.5-5.570313 0-8.449219-3.195312-8.449219-9.5zm0 0"
              />
              <path
                d="m277.253906 181.007812h22.75v9.617188c0 3.644531 4.5 5.300781 8.5 5.300781s8.5-1.65625 8.5-5.300781v-9.617188h3.550782c2.675781 0 5.300781-4.113281 5.300781-8 0-3.710937-1.660157-8-5.300781-8h-3.550782v-6.382812c0-3.835938-4.671875-5.199219-8.5-5.199219s-8.5 1.363281-8.5 5.199219v6.382812h-7.140625l17.351563-34.742187c.320312-.648437.503906-1.351563.539062-2.070313 0-3.96875-5.371094-6.382812-8.300781-6.382812-2.953125-.058594-5.667969 1.613281-6.941406 4.28125l-23.097657 45.289062c-.554687 1.078126-.851562 2.269532-.859374 3.480469 0 3.53125 2.292968 6.144531 5.699218 6.144531zm0 0"
              />
            </g>
          </svg>
          <h1>404 not found</h1>
          <div class="lead">it seems you're in the wrong page</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
};
</script>

<style scoped></style>
